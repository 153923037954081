'use client';

import { useState, useEffect, useRef, useCallback } from 'react';
import Cookie from 'js-cookie';
import gsap from 'gsap';

//* Style
import CookieConsentStyle from './style';

//* Components
import Container from '../Container';
import Text from '../Text';
import Button from '../Button';

const CookieConsent = () => {
	//! Ref
	const popupRef = useRef();

	//! State
	const [active, setActive] = useState(false);

	//! Did Mount
	useEffect(() => {
		const cpCookie = Cookie.get('accepted');

		if (!cpCookie) {
			setActive(true);
		}
	}, []);

	useEffect(() => {
		if (active) {
			gsap.to(popupRef.current, {
				yPercent: 0,
				x: 0,
				y: 0,
				force3D: true,
				delay: 1.5,
				duration: 0.7,
				ease: 'power1.out',
			});
		}
	}, [active]);

	//! Handle accept click
	const handleAcceptClick = useCallback(() => {
		Cookie.set('accepted', true);

		gsap.to(popupRef.current, {
			yPercent: 200,
		}).then(() => setActive(false));
	}, [active]);

	//! Handle close click
	const handleCloseClick = useCallback(() => {
		gsap.to(popupRef.current, {
			yPercent: 200,
		}).then(() => setActive(false));
	}, [active]);

	return active ? (
		<CookieConsentStyle ref={popupRef}>
			<Container
				row
				full
				className={'cookie-consent'}>
				<div className={`col-12`}>
					<Text className={`h3 black1000 font-montserrat-medium`}>Cookies</Text>
				</div>

				<div className={`col-12`}>
					<Text
						className={`h6 black1000 font-montserrat padding-top-16 inner-text-cookie`}
						text={`cookieTest`}
					/>
				</div>

				<div className={`col-12 d-flex padding-top-24 cookie-buttons-wrapper`}>
					<Button
						colorType={'white'}
						text={'deny'}
						onClick={handleCloseClick}
						className={'close-cookie-btn'}
					/>

					<Button
						colorType={'red'}
						text={'accept'}
						onClick={handleAcceptClick}
						className={'padding-left-16'}
					/>
				</div>
			</Container>
		</CookieConsentStyle>
	) : null;
};

export default CookieConsent;
