import styled from 'styled-components';

const FooterStyle = styled.footer`
	--footerLowerMarginTop: var(--sp7x);
	--footerLowerPadding: var(--sp9x);
	--footerLogoWidth: var(--sp66x);
	--footerPaddingTop: var(--sp17x);
	--footerMarginBottom: var(--sp12x);
	--footerImageWidth: var(--sp76x);
	--footerImagePaddingTop: var(--sp106x);
	--footerMaxWidth: var(--sp86x);
	--phoneEmailMarginBottom: var(--sp4x);
	--footerBorderRadius: var(--sp4x);

	--socialWrapMarginTop: var(--sp4x);
	--socialSizes: calc(var(--sp5x) + var(--sp0-5x));
	--socialMarginLeft: var(--sp2-5x);

	margin-bottom: var(--footerMarginBottom);

	.footer-lower {
		margin-top: var(--footerLowerMarginTop);
		background-color: var(--white);
		padding: var(--footerLowerPadding);
		border-radius: var(--footerBorderRadius);

		.first-row {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			.socials-wrap {
				display: flex;
				justify-content: flex-end;
				margin-top: var(--socialWrapMarginTop);

				.social {
					width: var(--socialSizes);
					height: var(--socialSizes);
					border-radius: 50%;
					background-color: var(--black1000);
					margin-left: var(--socialMarginLeft);
					display: flex;
					justify-content: center;
					align-items: center;
					transition: background-color var(--trTime) ease-out;

					.social-icon {
						&::before {
							color: var(--white);
							transition: color var(--trTime) ease-out;
						}
					}

					&:first-child {
						margin-left: unset;
					}

					@media (hover: hover) {
						&:hover {
							/* background-color: var(--white); */
							background-color: var(--green1000);
							opacity: 1;

							.social-icon {
								&::before {
									/* color: var(--black1000); */
								}
							}
						}
					}
				}
			}

			.footer-logo {
				width: var(--footerLogoWidth);
				padding-top: var(--footerPaddingTop);

				img {
					object-fit: contain;
				}
			}

			.footer-image {
				width: var(--footerImageWidth);
				padding-top: var(--footerImagePaddingTop);

				img {
					object-fit: contain;
				}
			}

			.footer-info {
				display: flex;
				flex-direction: column;
				text-align: right;
				max-width: var(--footerMaxWidth);

				.phone,
				.email {
					width: fit-content;
					margin-left: auto;
					margin-bottom: var(--phoneEmailMarginBottom);
				}
			}
		}
	}

	.second-row {
		display: flex;
		justify-content: space-between;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--footerLowerMarginTop: var(--sp5x);
		--footerLowerPadding: var(--sp7x);
		--footerLogoWidth: var(--sp54x);
		--footerPaddingTop: var(--sp13x);
		--footerMarginBottom: var(--sp10x);
		--footerImageWidth: var(--sp63x);
		--footerImagePaddingTop: var(--sp88x);
		--footerMaxWidth: var(--sp62x);
		--phoneEmailMarginBottom: var(--sp3x);
		--footerBorderRadius: var(--sp4x);

		--socialWrapMarginTop: var(--sp3x);
		--socialSizes: var(--sp5x);
		--socialMarginLeft: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--footerLowerMarginTop: var(--sp4x);
		--footerLowerPadding: var(--sp6x);
		--footerLogoWidth: var(--sp42x);
		--footerPaddingTop: var(--sp11x);
		--footerMarginBottom: var(--sp8x);
		--footerImageWidth: var(--sp50x);
		--footerImagePaddingTop: var(--sp67x);
		--footerMaxWidth: var(--sp49x);
		--phoneEmailMarginBottom: var(--sp3x);
		--footerBorderRadius: var(--sp4x);

		--socialWrapMarginTop: var(--sp3x);
		--socialSizes: var(--sp4x);
		--socialMarginLeft: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--footerLowerMarginTop: var(--sp4x);
		--footerLowerPadding: var(--sp6x);
		--footerLogoWidth: var(--sp42x);
		--footerPaddingTop: var(--sp11x);
		--footerMarginBottom: var(--sp7x);
		--footerImageWidth: var(--sp50x);
		--footerImagePaddingTop: var(--sp67x);
		--footerMaxWidth: var(--sp49x);
		--phoneEmailMarginBottom: var(--sp3x);
		--footerBorderRadius: var(--sp4x);

		--socialWrapMarginTop: var(--sp3x);
		--socialSizes: var(--sp4x);
		--socialMarginLeft: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--footerLowerMarginTop: var(--sp4x);
		--footerLowerPadding: var(--sp5x);
		--footerLogoWidth: var(--sp36x);
		--footerPaddingTop: var(--sp9x);
		--footerMarginBottom: var(--sp6x);
		--footerImageWidth: var(--sp50x);
		--footerImagePaddingTop: var(--sp67x);
		--footerMaxWidth: var(--sp37x);
		--phoneEmailMarginBottom: var(--sp2x);
		--footerBorderRadius: var(--sp4x);

		--socialWrapMarginTop: var(--sp2x);
		--socialSizes: var(--sp4x);
		--socialMarginLeft: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--footerLowerMarginTop: var(--sp3x);
		--footerLowerPadding: var(--sp5x);
		--footerLogoWidth: var(--sp21x);
		--footerPaddingTop: var(--sp5x);
		--footerMarginBottom: var(--sp6x);
		--footerImageWidth: var(--sp34x);
		--footerImagePaddingTop: var(--sp47x);
		--footerMaxWidth: var(--sp31x);
		--phoneEmailMarginBottom: var(--sp2x);
		--footerBorderRadius: var(--sp4x);

		--socialWrapMarginTop: var(--sp2x);
		--socialSizes: var(--sp4x);
		--socialMarginLeft: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--footerLowerMarginTop: var(--sp3x);
		--footerLowerPadding: var(--sp5x);
		--footerLogoWidth: var(--sp29x);
		--footerPaddingTop: var(--sp7x);
		--footerMarginBottom: var(--sp6x);
		--footerImageWidth: var(--sp29x);
		--footerImagePaddingTop: var(--sp40x);
		--footerMaxWidth: var(--sp31x);
		--phoneEmailMarginBottom: var(--sp2x);
		--footerBorderRadius: var(--sp4x);

		--socialWrapMarginTop: var(--sp2x);
		--socialSizes: var(--sp4x);
		--socialMarginLeft: var(--sp2x);

		.first-row {
			flex-direction: column;
		}

		.first-row-mobile {
			display: flex;
			justify-content: space-between;
			width: 100%;

			.footer-image {
				margin-left: auto;
			}
		}

		.footer-lower {
			.first-row {
				.socials-wrap {
					justify-content: flex-start;
				}

				.footer-info {
					margin-top: var(--sp6x);
					text-align: left;

					.phone,
					.email {
						margin-left: unset;
					}
				}
			}

			.second-row {
				margin-top: var(--sp5x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--footerLowerMarginTop: var(--sp2x);
		--footerLowerPadding: var(--sp3x);
		--footerLogoWidth: var(--sp21x);
		--footerPaddingTop: var(--sp5x);
		--footerMarginBottom: var(--sp4x);
		--footerImageWidth: var(--sp39x);
		--footerImagePaddingTop: var(--sp55x);
		--footerMaxWidth: 100%;
		--phoneEmailMarginBottom: var(--sp2x);
		--footerBorderRadius: var(--sp2x);

		--socialWrapMarginTop: var(--sp2x);
		--socialSizes: var(--sp4x);
		--socialMarginLeft: var(--sp2x);

		.first-row-mobile {
			display: flex;
			flex-direction: column;
			width: 100%;

			.footer-image {
				margin-left: auto;
			}
		}

		.footer-lower {
			.first-row {
				.socials-wrap {
					justify-content: flex-start;
				}

				.footer-info {
					max-width: var(--sp30x);
					margin-top: var(--sp6x);
					text-align: left;

					.phone,
					.email {
						margin-left: unset;
					}
				}

				.footer-image {
					width: 100%;
					margin-top: var(--sp5x);
				}
			}
		}

		.second-row {
			flex-direction: column;
			margin-top: var(--sp5x);

			.concept-link {
				margin-top: var(--sp2x);
			}
		}
	}
`;

export default FooterStyle;
