'use client';

import { useMemo } from 'react';
import { usePathname } from 'next/navigation';

//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';
import withDataContext from '@/context/consumerHOC/DataConsumer';
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Style
import FooterStyle from './style';

//* Components
import Text from '@/components/common/Text';
import Icon from '@/components/common/Icon';
import Image from '@/components/common/Image';
import Container from '@/components/common/Container';
import CustomLink from '@/components/common/CustomLink';
import CallToAction from '../../../modules/home/CallToAction';

const AppFooter = ({ globalData, winWidth, translate, selectedLang }) => {
	//! Router
	const pathname = usePathname();

	//! Not found check
	const is404 = useMemo(() => {
		return pathname === `/${selectedLang}/not-found/` || pathname === `/not-found/`;
	}, [pathname, selectedLang]);

	return !is404 ? (
		<FooterStyle className={`footer`}>
			<CallToAction offer_text={globalData.offer_text} />

			<Container>
				<div className='footer-lower'>
					<div className='first-row'>
						{winWidth < 1024 ? (
							<div className='first-row-mobile'>
								<div className='image-info-wrap'>
									<Image
										className={'footer-logo'}
										src={'/images/logo.svg'}
									/>

									<div className='footer-info'>
										<CustomLink
											external
											url={`mailto:${globalData.email}`}
											className='h3 main-blue font-montserrat-medium email'>
											{globalData.email}
										</CustomLink>

										<CustomLink
											external
											url={`tel:${globalData.phone_number}`}
											className='h3 main-blue font-montserrat-medium phone'>
											{globalData.phone_number}
										</CustomLink>

										<Text className={'h3 font-montserrat-medium main-blue'}>
											{globalData.address_line_1} <span className='font-montserrat'>{globalData.address_line_2}</span>
										</Text>

										<div className='socials-wrap'>
											{globalData?.socials?.linkedin ? (
												<CustomLink
													external
													className='social'
													url={globalData.socials.linkedin}>
													<Icon
														className={'h6 social-icon'}
														name={'linkedin'}
													/>
												</CustomLink>
											) : null}

											{globalData?.socials?.facebook ? (
												<CustomLink
													external
													className='social'
													url={globalData.socials.facebook}>
													<Icon
														className={'h6 social-icon'}
														name={'facebook'}
													/>
												</CustomLink>
											) : null}

											{globalData?.socials?.twitter ? (
												<CustomLink
													external
													className='social'
													url={globalData.socials.twitter}>
													<Icon
														className={'h6 social-icon'}
														name={'twitter'}
													/>
												</CustomLink>
											) : null}

											{globalData?.socials?.instagram ? (
												<CustomLink
													external
													className='social'
													url={globalData.socials.instagram}>
													<Icon
														className={'h6 social-icon'}
														name={'instagram'}
													/>
												</CustomLink>
											) : null}
										</div>
									</div>
								</div>

								<Image
									className={'footer-image'}
									src={'/images/footer/footer-image.svg'}
								/>
							</div>
						) : (
							<>
								<Image
									className={'footer-logo'}
									src={'/images/logo.svg'}
								/>

								<Image
									className={'footer-image'}
									src={'/images/footer/footer-image.svg'}
								/>

								<div className='footer-info'>
									<CustomLink
										external
										url={`mailto:${globalData.email}`}
										className='h3 main-blue font-montserrat-medium email'>
										{globalData.email}
									</CustomLink>

									<CustomLink
										external
										url={`tel:${globalData.phone_number}`}
										className='h3 main-blue font-montserrat-medium phone'>
										{globalData.phone_number}
									</CustomLink>

									<Text className={'h3 font-montserrat-medium main-blue'}>
										{globalData.address_line_1} <span className='font-montserrat'>{globalData.address_line_2}</span>
									</Text>

									<div className='socials-wrap'>
										{globalData?.socials?.linkedin ? (
											<CustomLink
												external
												className='social'
												url={globalData.socials.linkedin}>
												<Icon
													className={'h6 social-icon'}
													name={'linkedin'}
												/>
											</CustomLink>
										) : null}

										{globalData?.socials?.facebook ? (
											<CustomLink
												external
												className='social'
												url={globalData.socials.facebook}>
												<Icon
													className={'h6 social-icon'}
													name={'facebook'}
												/>
											</CustomLink>
										) : null}

										{globalData?.socials?.twitter ? (
											<CustomLink
												external
												className='social'
												url={globalData.socials.twitter}>
												<Icon
													className={'h6 social-icon'}
													name={'twitter'}
												/>
											</CustomLink>
										) : null}

										{globalData?.socials?.instagram ? (
											<CustomLink
												external
												className='social'
												url={globalData.socials.instagram}>
												<Icon
													className={'h6 social-icon'}
													name={'instagram'}
												/>
											</CustomLink>
										) : null}
									</div>
								</div>
							</>
						)}
					</div>

					<div className='second-row'>
						<Text className={'p p1 font-montserrat-medium main-blue'}>
							©{new Date().getFullYear()}. {translate('allRights')}
						</Text>

						<p className={'p p1 concept-link font-montserrat-medium main-blue'}>
							<span>{translate('by')}</span>
							&nbsp;
							<CustomLink
								external
								className={'concept-link'}
								url={'https://conceptstudio.com/'}>
								Concept Studio
							</CustomLink>
							{selectedLang === 'hy' ? <span>{translate('by_hy_version')}</span> : null}
						</p>
					</div>
				</div>
			</Container>
		</FooterStyle>
	) : null;
};

export default withUIContext(withLanguageContext(withDataContext(AppFooter, ['globalData']), ['translate']), ['winWidth']);
