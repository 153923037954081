import { memo as Memo, useEffect, useCallback } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

//* HOCs
import withUIContext from '@/context/consumerHOC/UIConsumer';
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Components
import Text from '@/components/common/Text';
import Icon from '@/components/common/Icon';

//* Style
import LanguageSwitcherStyle from './style';

const LanguageSwitcher = Memo(({ languages, className, isLanguageSwitcherActive, setLanguageSwitcherActive, selectedLang, isWhite, ...props }) => {
	const pathname = usePathname();

	//! Check className
	const checkClass = useCallback((el, cl) => el.classList.contains(cl) || !!el.closest(`.${cl}`), []);

	//! Detect click outside
	const handleClickOutside = useCallback(
		(event) => {
			if (checkClass(event.target, `language-switcher`) && !isLanguageSwitcherActive) {
				setLanguageSwitcherActive(true);
			} else if (!checkClass(event.target, 'body-container') && isLanguageSwitcherActive) {
				setLanguageSwitcherActive(false);
			}
		},
		[isLanguageSwitcherActive]
	);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClickOutside]);

	return (
		<LanguageSwitcherStyle className={`language-switcher ${className || ''}`}>
			<div className={`selected-lang-wrapper`}>
				<Icon
					className={`language-arrow ${isLanguageSwitcherActive ? 'active' : ''} ${isWhite ? 'whiteClass' : ''}`}
					name={`arrow`}
				/>
				<Text
					text={languages[selectedLang]?.params.name}
					className={`h6 main-blue font-montserrat-medium selected-lang ${isWhite ? 'whiteClass' : ''}`}
				/>
			</div>

			<div className={`languages-cont`}>
				<div className={`body-container ${isLanguageSwitcherActive ? 'active' : ''}`}>
					{Object.keys(languages)
						.filter((lang) => lang !== selectedLang)
						.map((lang) => {
							const route = pathname.replace(`/${selectedLang}`, '');

							return (
								<Link
									key={lang}
									href={`/[lang]${lang}`}
									as={`/${lang}${route}`}
									className={`h6 font-montserrat-medium main-blue lang-item  ${isWhite ? 'whiteClass' : ''}`}>
									{languages[lang]?.params.name}
								</Link>
							);
						})}
				</div>
			</div>
		</LanguageSwitcherStyle>
	);
});

export default withLanguageContext(withUIContext(LanguageSwitcher, ['isBurgerMenuOpen']), ['languages', 'selectedLang']);
