import styled from 'styled-components';

const LanguageSwitcherStyle = styled.div`
	margin-right: var(--sp4x);

	.selected-lang-wrapper {
		display: flex;
		align-items: center;
		cursor: pointer;

		.language-arrow {
			margin-right: var(--sp1x);
			transition: var(--trTime);
			&::before {
				transition: var(--trTime);
				color: var(--white);
			}
			&.active {
				transform: rotate(180deg);
			}
			&.whiteClass {
				&::before {
					color: var(--black1000);
				}
			}
		}
		/* @media (hover: hover) {
			&:hover {
				.language-arrow {
					&::before {
						color: var(--green1000);
					}
				}
				.selected-lang {
					color: var(--green1000);
				}
			}
		} */

		.selected-lang {
			text-transform: capitalize;
			color: var(--white);
			transition: var(--trTime);
			&.whiteClass {
				color: var(--black1000);
			}
		}
	}

	.languages-cont {
		position: relative;

		.body-container {
			display: flex;
			flex-direction: column;
			position: absolute;
			top: 0;
			right: 0;
			height: 0;
			overflow: hidden;

			.lang-item {
				margin-top: var(--sp1x);
				text-transform: capitalize;
				transition: var(--trTime);
				color: var(--white);
				&.whiteClass {
					color: var(--black1000);
				}

				@media (hover: hover) {
					&:hover {
						color: var(--green1000);
					}
				}
			}

			&.active {
				height: auto;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		margin-right: var(--sp2x);
	}
`;

export default LanguageSwitcherStyle;
