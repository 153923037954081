import styled from 'styled-components';

const CookieConsentStyle = styled.div`
	--padding: var(--sp5x);
	--paddingT16: var(--sp2x);
	--paddingT32: var(--sp4x);
	--sizeCockies: 50%;
	/* --width: calc(calc(100% - var(--contPaddingLR) * 2) / 2); */

	/* width: var(--width); */
	width: var(--sizeCockies);
	padding: var(--padding);
	border-radius: var(--sp2x);
	position: fixed;
	right: var(--contPaddingLR);
	transform: translateY(200%);
	bottom: calc(var(--contPaddingLR) / 2);
	border: 1px solid var(--white);
	background-color: var(--white);
	z-index: 100000;

	.d-flex {
		display: flex;
	}

	.padding-top-16 {
		padding-top: var(--paddingT16);
	}

	.padding-left-16 {
		margin-left: var(--paddingT16);
	}

	.padding-top-24 {
		padding-top: var(--paddingT32);
	}

	.cookie-buttons-wrapper {
		align-items: center;
		justify-content: end;
	}
	.inner-text-cookie {
		line-height: var(--lineHeight2);
	}

	.close-cookie-btn {
		/* text-decoration: underline;
		color: var(--black1000);
		font-size: var(--h6);
		font-family: var(--montserrat);
		font-weight: 500 !important; */
		cursor: pointer;

		/* @media (hover: hover) {
			&:hover {
				text-decoration: none;
			}
		} */
	}

	/* //! 1024
	@media only screen and (max-width: 1024px) {
		--width: calc(100% - (var(--contPaddingLR) * 2));
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--width: calc(100% - (var(--contPaddingLR) * 2));
		--padding: var(--sp2x);
	} */

	//! 1920
	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
	}

	//! 1510
	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
	}

	//! 1440
	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
	}

	//! 1280
	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
		--padding: var(--sp4x);
		--paddingT16: var(--sp1x);
		--paddingT32: var(--sp3x);
	}

	//! 1024
	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
		--padding: var(--sp3x);
		--paddingT16: var(--sp2x);
		--paddingT32: var(--sp2x);
	}

	//! 768
	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
		--padding: var(--sp3x);
		--paddingT16: var(--sp2x);
		--paddingT32: var(--sp2x);
		--sizeCockies: 70%;
	}

	//! 414
	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		right: 0;
		--sizeCockies: 100%;
	}
`;

export default CookieConsentStyle;
