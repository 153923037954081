import styled from 'styled-components'

const LogoStyle = styled.div`
	--logoWidth: var(--sp47x);
	--logoHeight: var(--sp12x);

	width: var(--logoWidth);

	a {
		width: 100%;
	}

	.imageCont {
		--proportion: var(--logoHeight);

		img {
			object-fit: contain;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--logoWidth: var(--sp35x);
		--logoHeight: var(--sp9x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--logoWidth: var(--sp28x);
		--logoHeight: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--logoWidth: var(--sp23x);
		--logoHeight: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--logoWidth: var(--sp23x);
		--logoHeight: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--logoWidth: var(--sp17x);
		--logoHeight: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--logoWidth: var(--sp17x);
		--logoHeight: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--logoWidth: var(--sp10x);
		--logoHeight: var(--sp2-5x);
	}
`

export default LogoStyle
