import(/* webpackMode: "eager" */ "/var/www/vhosts/sundayeveryday.am/httpdocs/frontend/src/components/common/AppFooter/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/sundayeveryday.am/httpdocs/frontend/src/components/common/AppHeader/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/sundayeveryday.am/httpdocs/frontend/src/components/common/CookieConsent/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/sundayeveryday.am/httpdocs/frontend/src/components/common/Popup/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/sundayeveryday.am/httpdocs/frontend/src/components/common/ThirdParties/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/sundayeveryday.am/httpdocs/frontend/src/context/providers/DataProvider.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/sundayeveryday.am/httpdocs/frontend/src/context/providers/LanguageProvider.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/sundayeveryday.am/httpdocs/frontend/src/context/providers/UIProvider.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/sundayeveryday.am/httpdocs/frontend/src/helpers/_useActiveSection.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/sundayeveryday.am/httpdocs/frontend/src/libs/styled-registry.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/sundayeveryday.am/httpdocs/frontend/src/styles/icons/style.css")