'use client';

import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { usePathname } from 'next/navigation';
import gsap from 'gsap';

//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';
import withDataContext from '@/context/consumerHOC/DataConsumer';
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Style
import HeaderLandingStyle from './style';

//* Components
import Logo from '@/components/common/Logo';
import Button from '@/components/common/Button';
import LanguageSwitcher from './LanguageSwitcher';
import PopupRequestCallOrReservation from '@/components/common/PopupRequestCallOrReservation';

const AppHeader = ({ winWidth, globalData, closeBurgerMenu, openPopup, selectedLang }) => {
	//! Router
	const pathname = usePathname();

	//! Refs
	const defaultScroll = useRef(0);
	const headerRef = useRef();

	//! Not found check
	const is404 = useMemo(() => {
		return pathname === `/${selectedLang}/not-found/` || pathname === `/not-found/`;
	}, [pathname, selectedLang]);

	//! States
	const [scrollDirection, setScrollDirection] = useState(true);
	const [isLanguageSwitcherActive, setLanguageSwitcherActive] = useState(false);
	const [headerHeight, setHeaderHeight] = useState();
	const [isWhite, setIsWhite] = useState(true);

	//! Header navigation function
	const handleNavigation = useCallback(() => {
		closeBurgerMenu(false);

		if (window.scrollY > defaultScroll.current && window.scrollY > 0) {
			setScrollDirection(false);
		} else if (window.scrollY < defaultScroll.current || window.scrollY <= 0) {
			if (window.scrollY < 200) {
				setIsWhite(true);
			} else {
				setIsWhite(false);
			}

			setScrollDirection(true);
		}

		defaultScroll.current = window.scrollY;
	}, [pathname]);

	//! Header navigation
	useEffect(() => {
		window.addEventListener('scroll', handleNavigation);

		return () => {
			window.removeEventListener('scroll', handleNavigation);
		};
	}, [handleNavigation, pathname]);

	useEffect(() => {
		pathname !== '/' ? headerRef.current && setHeaderHeight(headerRef.current?.getBoundingClientRect().height) : setHeaderHeight(0);
	}, [headerRef, pathname, winWidth]);

	useEffect(() => {
		gsap.to(headerRef.current, { opacity: 1, ease: 'power3.easeOut', duration: 1, delay: 0.8 });
	});

	return (
		<HeaderLandingStyle
			ref={headerRef}
			$header_height={headerHeight}
			className={`header ${is404 ? '' : 'opacity-0'}  ${scrollDirection ? `visible` : ``}`}>
			<Logo
				white={!is404 && isWhite}
				black={is404 || !isWhite}
				className={'header-logo'}
			/>

			<div className='buttons-wrap'>
				<LanguageSwitcher
					isWhite={!isWhite || is404}
					setLanguageSwitcherActive={setLanguageSwitcherActive}
					isLanguageSwitcherActive={isLanguageSwitcherActive}
				/>

				<Button
					external
					colorType={'white'}
					url={`tel:${globalData.phone_number}`}
					className={'header-button phone-button'}>
					{globalData.phone_number}
				</Button>

				<Button
					colorType={'red'}
					text={'requestACall'}
					className={'header-button'}
					onClick={() => openPopup(<PopupRequestCallOrReservation />)}
				/>
			</div>
		</HeaderLandingStyle>
	);
};

export default withUIContext(withLanguageContext(withDataContext(AppHeader, ['globalData']), ['selectedLang']), ['winWidth', 'closeBurgerMenu', 'openPopup']);
