import styled from 'styled-components';

const HeaderLandingStyle = styled.header`
	--headerPadding: var(--sp8x) var(--sp12x);
	--phoneButtonMarginRight: var(--sp4x);
	--buildingRouteMarginRight: var(--sp4x);

	position: fixed;
	width: 100%;
	top: -100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: var(--headerPadding);
	transition: top var(--trTime);
	z-index: 7;
	/* opacity: 0; */

	/* & + main {
		${({ $header_height }) => ($header_height ? `margin-top: ${$header_height}px` : ``)}
	} */

	&.visible {
		top: 0;
	}

	.buttons-wrap {
		display: flex;
		align-items: center;

		.phone-button {
			margin-right: var(--phoneButtonMarginRight);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--headerPadding: var(--sp7x) var(--sp10x);
		--phoneButtonMarginRight: var(--sp4x);
		--buildingRouteMarginRight: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--headerPadding: var(--sp5x) var(--sp8x);
		--phoneButtonMarginRight: var(--sp2x);
		--buildingRouteMarginRight: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--headerPadding: var(--sp5x) var(--sp7x);
		--phoneButtonMarginRight: var(--sp2x);
		--buildingRouteMarginRight: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--headerPadding: var(--sp5x) var(--sp6x);
		--phoneButtonMarginRight: var(--sp2x);
		--buildingRouteMarginRight: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--headerPadding: var(--sp5x) var(--sp6x);
		--phoneButtonMarginRight: var(--sp2x);
		--buildingRouteMarginRight: var(--sp2x);

		.buttons-wrap {
			margin-left: auto;
		}

		.header-logo {
			margin-right: var(--sp5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--headerPadding: var(--sp5x) var(--sp4x);
		--phoneButtonMarginRight: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--headerPadding: var(--sp3x);
		--phoneButtonMarginRight: 0;
		.phone-button {
			display: none;
		}
	}
`;

export default HeaderLandingStyle;
