'use client';

const ThirdParties = () => {
    return (
        <>
            {/* Yandex Metrika */}
            <script
                id="yandex-metrica"
                dangerouslySetInnerHTML={{
                    __html: `
                    (function(m,e,t,r,i,k,a){m[i]=m[i]function(){(m[i].a=m[i].a[]).push(arguments)};
                    m[i].l=1*new Date();
                    for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
                    k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
                
                    ym(97222854, "init", {
                        clickmap:true,
                        trackLinks:true,
                        accurateTrackBounce:true,
                        webvisor:true
                    });
                `
                }}
            />
            <noscript dangerouslySetInnerHTML={{
                __html: `<div><img src="https://mc.yandex.ru/watch/97222854" style="position:absolute; left:-9999px;" alt=""/></div>`
            }}>
            </noscript>

            {/* Service Worker */}
            <script
                dangerouslySetInnerHTML={{
                    __html: `
                                var sw = {
                                    "url": "\/service-worker.js"
                                };
                                if ( 'serviceWorker' in navigator ) {
                                    window.addEventListener( 'load', function () {
                                        console.log( 'loading SW' )
                                        navigator.serviceWorker.register( sw.url )
                                            .then( function ( registration ) {
                                                console.log( 'Service worker registered' );
                                            } )
                                            .catch( function ( error ) {
                                                console.log( 'Registration failed with ' + error );
                                            } );
                                    } );
                                }
                            `,
                }}
            />
        </>
    )
}

export default ThirdParties;