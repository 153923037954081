import { memo as Memo } from 'react';

//* Component's
import CustomLink from '@/components/common/CustomLink';
import Image from '@/components/common/Image';

//* Style
import LogoStyle from './style';

const Logo = Memo(({ className, type, white }) => {
	return (
		<LogoStyle
			className={className || ''}
			type={type}>
			<CustomLink
				content={
					<Image
						src={white ? `/images/logo-white.svg` : `/images/logo.svg`}
						alt={`Logo`}
					/>
				}
				url={'/'}
			/>
		</LogoStyle>
	);
});

export default Logo;
