import { useRef, useCallback, useEffect, useMemo } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger, SplitText } from 'gsap/all';

//* HOC's
import { withUIContext } from '@/context';

//* Style
import CallToActionStyle from './style';

//* Components
import Text from '@/components/common/Text';
import Container from '@/components/common/Container';
import PopupRequestCallOrReservation from '../../../components/common/PopupRequestCallOrReservation';

const CallToAction = ({ winWidth, openPopup, offer_text }) => {
	//! Checks
	const isDesktop = useMemo(() => {
		return winWidth >= 1280;
	}, [winWidth]);

	//! Refs
	const cursorRef = useRef();
	const contRef = useRef();
	const sectionRef = useRef();
	const typingTextFooter = useRef();

	//! Plugins
	useEffect(() => {
		gsap.registerPlugin(SplitText, ScrollTrigger);
	}, []);

	//! Handle mouse move
	const handleMouseMove = useCallback(
		(e) => {
			if (isDesktop) {
				const cursorContainer = contRef.current.getBoundingClientRect();

				const x = e.clientX - cursorContainer.left;
				const y = e.clientY - cursorContainer.top;

				gsap.to(cursorRef.current, { duration: 0.3, opacity: 1, left: x, top: y, scale: 1 });
			}
		},
		[isDesktop]
	);

	//! Handle mouse leave
	const handleMouseLeave = useCallback(() => {
		if (isDesktop) {
			gsap.to(cursorRef.current, { duration: 0.3, opacity: 0, scale: 0 });
		}
	}, [isDesktop]);

	useEffect(() => {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: typingTextFooter.current,
				start: `top bottom`,
				end: 'top top',
				// markers: true,
			},
		});

		const mySplitText = new SplitText(typingTextFooter.current, { type: 'chars,words' });
		const chars = mySplitText.chars;

		tl.from(chars, {
			duration: 0.6,
			opacity: 1,
			autoAlpha: 0,
			ease: 'power2.out',
			stagger: 0.05,
		});
	}, [typingTextFooter.current]);

	function clickPopupButton() {
		openPopup(<PopupRequestCallOrReservation />);
	}

	return (
		<CallToActionStyle ref={sectionRef}>
			<Container>
				<div className={'row'}>
					<div
						className='call-to-action dark-green-bg'
						onClick={isDesktop ? clickPopupButton : null}
						onMouseMove={handleMouseMove}
						onMouseLeave={handleMouseLeave}
						ref={contRef}>
						{isDesktop ? (
							<div
								ref={cursorRef}
								className={`call-to-action-drag-container`}>
								<Text
									tag={`span`}
									className={`h5 font-montserrat-medium call-to-action-drag`}
									text={`requestACall`}
								/>
							</div>
						) : null}
						<div className='upper-text-wrap col-11 col-t-7 col-ts-12'>
							<Text
								className={'h1 white upper-text font-montserrat-medium centerText'}
								ref={typingTextFooter}>
								{offer_text}
							</Text>
						</div>
						<div className='static-cursor'>
							<Text
								onClick={clickPopupButton}
								tag={`span`}
								className={`h5 font-montserrat-medium call-to-action-drag`}
								text={`requestACall`}
							/>
						</div>
					</div>
				</div>
			</Container>
		</CallToActionStyle>
	);
};

export default withUIContext(CallToAction, ['winWidth', 'openPopup']);
